<template>
  <v-card>
    <v-window v-model="window" touchless>

      <v-window-item :key="0">
        <v-card-text>
          <v-alert
            type="error"
            :value="true"
            prominent text
          >
            If you continue you will overwrite the current schedule and lose any matches that have already been played.
          </v-alert>
          <div class="d-flex justify-end">

            <v-checkbox
              class="shrink"
              label="I only want to reschedule this round"
              v-model="thisRoundOnly"
              color="success"
            ></v-checkbox>
          </div>
        </v-card-text>
      </v-window-item>

      <!-- Courts & Time Slots -->
      <v-window-item :key="1">
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="6">
              <div class="title">Which courts are you going to use?</div>
              <div class="caption">To update a court name, click it</div>
              <v-data-table
                :headers="[{ text: 'Courts', value: 'name', sortable: false }]"
                :items="courts"
                hide-default-footer
                show-select
                v-model="selectedCourts"
                item-key="id"
                dense
                :items-per-page="-1"
              >
                <template v-slot:[`header.data-table-select`]="{ props, on }">
                  <v-simple-checkbox
                    color="warning"
                    v-if="props.indeterminate"
                    v-ripple
                    v-bind="props"
                    :value="props.indeterminate"
                    v-on="on"
                  ></v-simple-checkbox>
                  <v-simple-checkbox
                    color="success"
                    v-if="!props.indeterminate"
                    v-ripple
                    v-bind="props"
                    v-on="on"
                  ></v-simple-checkbox>
                </template>
                <template v-slot:[`item.data-table-select`]="{ select, isSelected }">
                  <v-simple-checkbox color="success" :value="isSelected" @input="select($event)"></v-simple-checkbox>
                </template>
                <template v-slot:[`item.name`]="props" v-if="false">
                  <v-edit-dialog
                    :return-value.sync="props.item.name"
                  >
                    {{ props.item.name }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="props.item.name"
                        label="Update Court Name"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12" sm="6">
              <div class="title">Which time slots are you using?</div>
              <v-data-table
                :headers="[{ text: 'Slots', value: 'time', sortable: false }]"
                :items="slots"
                hide-default-footer
                show-select
                v-model="selectedSlots"
                item-key="id"
                dense
              >
                <template v-slot:[`header.data-table-select`]="{ props, on }">
                  <v-simple-checkbox
                    color="warning"
                    v-if="props.indeterminate"
                    v-ripple
                    v-bind="props"
                    :value="props.indeterminate"
                    v-on="on"
                  ></v-simple-checkbox>
                  <v-simple-checkbox
                    color="success"
                    v-if="!props.indeterminate"
                    v-ripple
                    v-bind="props"
                    v-on="on"
                  ></v-simple-checkbox>
                </template>
                <template v-slot:[`item.data-table-select`]="{ select, isSelected }">
                  <v-simple-checkbox color="success" :value="isSelected" @input="select($event)"></v-simple-checkbox>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-window-item>

      <!-- Match Settings -->
      <v-window-item :key="2">
        <v-card-text>
          <div class="title">Match Settings</div>
          <v-radio-group v-model="matchCount" row>
            <v-radio color="success" label="Single headers" :value="1"></v-radio>
            <v-radio color="success" label="Double headers" :value="2"></v-radio>
            <v-radio color="success" label="Triple headers" :value="3"></v-radio>
          </v-radio-group>
          <match-settings-editor
            :setting="matchSettings"
            :edit="true"
            :notime="true"
          ></match-settings-editor>
        </v-card-text>
      </v-window-item>

      <!-- Byes -->
      <v-window-item :key="3">
        <v-card-text>
          <div class="text-end">
            <v-btn
              color="color3"
              text x-small
              @click.stop="adv = !adv"
            >
              {{this.adv ? 'simple' : 'advanced'}}
            </v-btn>
          </div>
          <div class="title">Byes and Special Requests</div>
          <v-expand-transition>
            <v-checkbox
              v-if="showB2B && false"
              label="Try to schedule double headers back to back"
              v-model="back2Back"
              color="success"
            ></v-checkbox>
          </v-expand-transition>
          <v-expand-transition>
            <v-autocomplete
              v-if="!advanced"
              :items="teamMatches"
              v-model="byes"
              item-text="name"
              return-object
              chips small-chips deletable-chips
              multiple
              color="color3"
              item-color="color3"
              label="Byes"
              persistent-hint
              hint="Select any teams that need a bye this week"
            ></v-autocomplete>
          </v-expand-transition>
          <v-expand-transition>
            <v-data-table
              v-if="advanced"
              :headers="[
                { text: 'Team', value: 'name' },
                { text: 'Matches', value: 'matches' }
              ]"
              :items="teamMatches"
              dense
              :items-per-page="-1"
            >
              <template v-slot:[`item.matches`]="{ item }">
                <v-select
                  :items="[
                    {text: 'Bye', value: 0},
                    {text: 'Single Header', value: 1},
                    {text: 'Double Header', value: 2}
                  ]"
                  v-model="item.matches"
                  dense
                  hide-details
                ></v-select>
              </template>
            </v-data-table>
          </v-expand-transition>
        </v-card-text>
      </v-window-item>

      <v-window-item :key="40" v-if="false">
        <v-card-text>
          <div class="title">Use same Settings?</div>
          <v-expand-transition>
            <div v-if="!showRounds">
              <div>Would you like to use these same settings for other rounds?</div>
              <v-btn
                color="success"
                class="mr-3"
                @click.stop="showRounds = true"
              >
                yes
              </v-btn>
              <v-btn color="error white-text" @click.stop="next">no</v-btn>
            </div>
          </v-expand-transition>
          <v-expand-transition>
            <div v-if="showRounds">
              <div>Select the additional rounds to apply these settings too</div>
              <v-checkbox
              class="ml-3"
                v-for="r in division.rounds.filter(f => f.poolPlay)"
                :key="r.id"
                :label="r.name" v-model="selectedRounds" :value="r.id"
                color="success" hide-details
                :disabled="round.id === r.id"
              ></v-checkbox>
              <v-btn color="color3" text small class="mt-3" @click.stop="toggleRounds">toggle all</v-btn>
            </div>
          </v-expand-transition>
        </v-card-text>
      </v-window-item>

      <!-- complete -->
      <v-window-item :key="4">
        <v-card-text>
          <div class="title">Create Schedule</div>
          <div class="text-center">
            <v-btn
              color="color3 color3Text--text"
              class="ma-5"
              :loading="loading"
              @click.stop="goFill"
            >
              Save & Create Schedule
            </v-btn>
          </div>
        </v-card-text>
      </v-window-item>

    </v-window>
    <v-divider></v-divider>
    <v-card-actions>
      <v-fab-transition>
        <v-btn
          color="color3 color3Text--text"
          fab
          @click.stop="prev"
          :disabled="loading"
        >
          <v-icon>fas fa-caret-left</v-icon>
        </v-btn>
      </v-fab-transition>
      <v-spacer></v-spacer>
      <v-fab-transition>
        <v-btn
          color="success white--text"
          fab
          :key="valid[window]"
          @click.stop="next"
          :disabled="!valid[window] || loading"
        >
          <v-icon>fas fa-caret-right</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import MatchSettingsEditor from '@/components/Tournament/RoundSettings/MatchSettings.vue'
import MatchSettings from '@/classes/MatchSettings'
import flatten from '@/helpers/ArrayFlatten'

export default {
  props: ['round', 'division'],
  data () {
    return {
      gotit: true,
      window: 1,
      courts: [],
      selectedCourts: [],
      slots: [],
      selectedSlots: [],
      matchCount: 1,
      matchSettings: new MatchSettings({
        poolsOf: 0,
        matchTypeId: 1
      }, true, true),
      teamMatches: [],
      adv: false,
      loading: false,
      selectedRounds: [],
      showRounds: false,
      back2Back: true,
      thisRoundOnly: false
    }
  },
  computed: {
    ...mapGetters(['tournament']),
    advanced () {
      return this.adv
    },
    settings () {
      return this.tournament.leagueSettings
    },
    valid () {
      return [
        this.gotit,
        this.selectedCourts.length > 0 && this.selectedSlots.length > 0,
        !!this.matchSettings.matchType,
        true
      ]
    },
    dto () {
      return {
        tournamentId: this.tournament.id,
        divisionId: this.division.id,
        roundId: this.round.id,
        roundIds: this.thisRoundOnly ? [this.round.id] : this.division.days.filter(f => f.poolPlay && f.number >= this.round.number).map(m => m.id),
        courts: this.selectedCourts.map(m => m.name),
        slots: this.selectedSlots.map(m => m.time),
        matchSettings: this.matchSettings.dto,
        back2Back: this.back2Back,
        teamIds: flatten(this.teamMatches.map(t => {
          return [...Array(t.matches).keys()].map(x => t.id)
        }))
      }
    },
    teams () {
      return this.teamMatches.map(m => {
        const t = this.tournament.teams.find(d => d.id === m.id)
        return {
          name: t.name,
          matches: m.matches
        }
      })
    },
    byes: {
      get () {
        return this.teamMatches.filter(f => f.matches === 0)
      },
      set (val) {
        this.teamMatches.filter(f => f.matches === 0).forEach(t => {
          t.matches = this.matchCount
        })
        val.forEach(v => {
          const t = this.teamMatches.find(f => f.id === v.id)
          t.matches = 0
        })
      }
    },
    showB2B () {
      return this.teamMatches.findIndex(f => f.matches > 1) > -1
    }
  },
  methods: {
    toggleRounds () {
      if (this.selectedRounds.length === 1) {
        this.selectedRounds = this.division.days.filter(f => f.poolPlay).map(m => m.id)
      } else {
        this.selectedRounds = [this.round.id]
      }
    },
    next () {
      let a = 1
      if (this.window === 2 && !this.thisRoundOnly) a = 2
      this.window += a
    },
    prev () {
      if (this.window <= 1) {
        this.init()
        this.$emit('cancel')
        return
      }
      var a = 1
      if (this.window === 4 && !this.thisRoundOnly) a = 2
      this.window -= a
    },
    init () {
      this.matchCount = 1
      this.selectedRounds = [this.round.id]
      if (this.round.hasMatches) this.window = 0
      this.courts = this.settings.courtNames.map((m, i) => {
        return {
          name: m,
          id: `court-${i}`
        }
      })
      this.selectedCourts = [...this.courts]
      this.slots = this.settings.slots.map((m, i) => {
        return {
          time: m,
          id: `slot-${i}`
        }
      })
      this.selectedSlots = [...this.slots]
      if (this.round && this.round.flight && this.round.flight.settings.length > 0) {
        this.matchSettings = new MatchSettings({
          poolsOf: 0,
          matchTypeId: this.round.flight.settings[0].matchTypeId
        }, true, true)
      }
      this.teamMatches = this.division.activeTeams.map(m => {
        return {
          id: m.id,
          name: m.name,
          matches: this.matchCount
        }
      })
    },
    goFill () {
      this.loading = true
      this.$VBL.tournament.league.fill(this.dto)
        .then(r => this.$emit('complete'))
        .catch(e => console.log('error'))
        .finally(() => {
          this.loading = false
        })
    }
  },
  watch: {
    matchCount: function (val) {
      this.teamMatches.forEach(t => {
        if (t.matches > 0) {
          t.matches = val
        }
      })
    }
  },
  mounted () {
    this.init()
  },
  components: {
    MatchSettingsEditor
  }
}
</script>
